import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReadMoreButton from '../ReadMoreButton'

const marginValues = { sm: '2.5rem', md: '5rem', lg: '10rem' }

const StyledSection = styled.section`
  & {
    position: relative;
    overflow: hidden;
    margin-top: ${({ marginTop }) => marginValues[marginTop]};
    margin-bottom: ${({ marginBottom }) => marginValues[marginBottom]};
  }
`
const ResponsiveVideo = styled.div`
  & {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export default function StaticImageVideo(props) {
  const { ytVideoId, image, marginTop, marginBottom, width, readMoreHide } =
    props.content

  return (
    <StyledSection
      marginTop={marginTop}
      marginBottom={marginBottom}
      css={[
        tw`relative`,
        width === 'contained' && tw`container md:px-10 px-6`,
        width === 'containSmall' && tw` max-w-screen-md md:px-10 px-6 mx-auto`,
      ]}
      className={`oneCol ${width === 'space' && 'mm-mx-dynamic'} ${
        readMoreHide && 'readMoreHide'
      }`}
    >
      {ytVideoId && (
        <ResponsiveVideo>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${ytVideoId}?controls=0`}
            title="Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </ResponsiveVideo>
      )}
      {image[0]?.localFile?.childImageSharp?.gatsbyImageData && (
        <GatsbyImage
          image={image[0]?.localFile?.childImageSharp?.gatsbyImageData}
          alt={image[0]?.title}
          css={tw``}
        />
      )}
      <ReadMoreButton text="Læs mere" />
    </StyledSection>
  )
}
